export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Admin']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-chart-pie'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Users',
        to: '/user/list',
        icon: 'cil-people'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Items',
        to: '/item',
        icon: 'cil-basket'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Orders',
        to: '/orders',
        icon: 'cil-basket'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'FeedBack',
        to: '/feedback',
        icon: 'cil-people'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Settings',
        to: '/settings',
        icon: 'cil-settings'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Broadcast',
        to: '/broadcast/list',
        icon: 'cil-chart-pie'
      }
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Log History',
      //   to: '/log-history',
      //   icon: 'cil-chart-pie'
      // }
    ]
  }
]